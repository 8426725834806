import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useLocation } from 'cccisd-react-router';
import query from './licenses.graphql';
import { LicensingContext } from '../../context/LicensingContext';

const Fortress = window.cccisd.fortress;
const routesThatNeedLicense = ['^/dashboard', '^/manage', '^/child', '^/parent', '^/survey/training', '^/d/elm0g3f'];

const LicensingWrapper = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [fbtProviderTraining, setFbtProviderTraining] = useState(null);
    const [fbtProviderRenewal, setFbtProviderRenewal] = useState(null);
    const location = useLocation();

    // gating is only for Providers who need to buy the course
    if (Fortress.user.acting.role.handle !== 'instructor') {
        return (
            <LicensingContext.Provider value={{ isLicenseNeeded: false, status: true }}>
                {children}
            </LicensingContext.Provider>
        );
    }

    const defaultMessage = `<div class="alert alert-warning">
            <p>
                <svg class="_3hZAm false undefined" viewBox="0 0 1024 1024">
                    <g>
                        <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM576 832h-128v-128h128v128zM576 576h-128v-384h128v384z">
                        </path>
                    </g>
                </svg>
                &nbsp;An active license is required to view this area.
            </p>
        </div>`;

    useEffect(() => {
        async function getData() {
            const resp = await client.query({
                query,
                variables: {
                    actingId: Fortress.user.acting.id,
                },
                fetchPolicy: 'network-only',
            });

            const data = resp.data.products;

            setFbtProviderTraining(_get(data, 'fbtProviderTraining'));
            setFbtProviderRenewal(_get(data, 'fbtProviderRenewal'));

            const hasInitialLicense = _get(data, 'fbtProviderTraining.ownedLicense.licenseId');
            if (!hasInitialLicense) {
                const msg = _get(
                    data,
                    'fbtProviderTraining.settings.customNotifications.noLicenseNoInherit',
                    defaultMessage
                );

                setIsLoading(false);
                setStatus(msg);
                return;
            }

            const isInitialExpired = _get(data, 'fbtProviderTraining.ownedLicense.isExpired', false);
            if (!isInitialExpired) {
                setIsLoading(false);
                setStatus(true);
                return;
            }

            const hasRenewalLicense = _get(data, 'fbtProviderRenewal.ownedLicense.licenseId');
            if (!hasRenewalLicense) {
                const msg = _get(
                    data,
                    'fbtProviderTraining.settings.customNotifications.expiredNoInherit',
                    defaultMessage
                );

                setIsLoading(false);
                setStatus(msg);
                return;
            }

            const isRenewalExpired = _get(data, 'fbtProviderRenewal.ownedLicense.isExpired', false);
            if (!isRenewalExpired) {
                setIsLoading(false);
                setStatus(true);
                return;
            }

            // final case is expired renewal
            const msg = _get(data, 'fbtProviderRenewal.settings.customNotifications.expiredNoInherit', defaultMessage);
            setIsLoading(false);
            setStatus(msg);
        }

        getData();
    }, []);

    if (isLoading || !status) {
        return <Loader loading />;
    }

    /*
     * status of `true` means you can show them the content
     * status of <String> means they do not have access and should see that message instead
     * status that is falsey means it's still loading
     */
    return (
        <LicensingContext.Provider
            value={{
                isLicenseNeeded: routesThatNeedLicense.some(r => {
                    const reg = new RegExp(r);
                    return reg.test(location.pathname);
                }),
                status,
                fbtProviderTraining,
                fbtProviderRenewal,
            }}
        >
            {children}
        </LicensingContext.Provider>
    );
};

export default LicensingWrapper;
