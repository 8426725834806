import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Manage as AppdefManage, Dashboard as AppdefDashboard } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';
import { ResourceCenter } from 'cccisd-laravel-resources';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import ParentManage from './pages/ParentManage'; // Include it into main bundle
import Activity from './pages/Activity'; // Include it into main bundle
import EnterVoucher from './pages/EnterVoucher';
import RedeemVoucher from './pages/RedeemVoucher';
import ResourceNotAvailable from './pages/ResourceNotAvailable'; // Include it into main bundle

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefManage,
    AppdefDashboard,
    Welcome,
    ParentManage,
    ResourceCenter,
    Activity,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
};

const actingRole = Fortress.user.acting.role.handle;

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact />
        <AppRoute path="/welcome" component={Welcome} />
        <AppRoute path="/login" component={Welcome} />

        <AppRoute path="/licensing/voucher/redeem" exact component={EnterVoucher} />
        <AppRoute path="/licensing/voucher/redeem/:code?" exact component={RedeemVoucher} />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluid)}

        <AppRoute path="/account" component={Nexus} />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Resource share links "Not Found" */}
        {!['child', 'parent', 'instructor', 'uberadmin'].includes(actingRole) && (
            <AppRoute
                path="/child/:tab?/:id?"
                component={ResourceNotAvailable}
                componentProps={{ intended: 'FBT Children' }}
            />
        )}
        {!['parent', 'instructor', 'uberadmin'].includes(actingRole) && (
            <AppRoute
                path="/parent/:tab?/:id?"
                component={ResourceNotAvailable}
                componentProps={{ intended: 'FBT Parents' }}
            />
        )}

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
