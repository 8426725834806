import React, { useRef, useState } from 'react';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';
import CheckmarkIcon from 'cccisd-icons/checkmark';
import CrossIcon from 'cccisd-icons/cross';
import PaperIcon from 'cccisd-icons/paperplane';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
import queryFamilies from './queryFamilies.graphql';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const SendReminder = ({ loadData }) => {
    const messageArea = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [families, setFamilies] = useState(null);
    const [tableFilter, setTableFilter] = useState({ studyId: '', username: '', group: '' });
    const [selectedPawns, setSelectedPawns] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [isSending, setIsSending] = useState(false);
    const [sendResponse, setSendResponse] = useState(null);

    async function fetchFamilies() {
        setIsLoading(true);
        const response = await client.query({
            query: queryFamilies,
            fetchPolicy: 'network-only',
        });
        let allFamilies = _get(response, 'data.roles.parentList', []) || [];
        allFamilies = allFamilies.map(fam => ({
            pawnId: _get(fam, 'pawn.pawnId', null),
            studyId: _get(fam, 'fields.studyId', null),
            username: _get(fam, 'user.username', null),
            firstName: _get(fam, 'user.firstName', null),
            lastName: _get(fam, 'user.lastName', null),
            group: _get(fam, 'parentGroup.class.group.label', null),
            phone: _get(fam, 'user.phone', null) || null,
        }));

        let initiallySelected = [];
        allFamilies.forEach(fam => {
            if (fam.phone) {
                initiallySelected.push(fam.pawnId + ''); // convert to string
            }
        });

        setFamilies(allFamilies);
        setSelectedPawns(initiallySelected);
        setIsLoading(false);
    }

    async function handleSubmit() {
        const message = messageArea.current.value;
        if (!message) {
            return setErrorMessage('Must provide a message.');
        }
        setIsSending(true);
        const response = await axios.post(Boilerplate.route('api.app.sendReminder'), {
            selectedPawns,
            message,
        });

        if (response.data.status === 'success') {
            setSendResponse(response.data.data);
        } else {
            setErrorMessage('Message failed. Could not send at this time.');
        }
        setIsSending(false);
    }

    function resetModalState() {
        // wait for close animation to finish
        setTimeout(() => {
            let initiallySelected = [];
            families.forEach(fam => {
                if (fam.phone) {
                    initiallySelected.push(fam.pawnId + '');
                }
            });

            setIsLoading(false);
            setSelectedPawns([]);
            setErrorMessage('');
            setIsSending(false);
            setSendResponse(null);
            setTableFilter({ studyId: '', username: '', group: '' });
        }, 500);
    }

    function handleClickCheckbox(event) {
        let { value } = event.target;
        let isSelected = selectedPawns.some(pawnId => parseInt(pawnId, 10) === parseInt(value, 10));

        if (isSelected) {
            setSelectedPawns(selectedPawns.filter(pawnId => parseInt(pawnId, 10) !== parseInt(value, 10)));
        } else {
            setSelectedPawns([...selectedPawns, value + '']);
        }
    }

    function _getFilteredPawnIds() {
        let filteredPawnIds = [];
        families.forEach(fam => {
            if (
                ['studyId', 'username', 'firstName', 'lastName', 'group'].every(field => {
                    if (!tableFilter[field]) {
                        return true;
                    }

                    if (!fam[field]) {
                        return false;
                    }

                    return fam[field].toLowerCase().includes(tableFilter[field].toLowerCase());
                })
            ) {
                filteredPawnIds.push(fam.pawnId + '');
            }
        });
        return filteredPawnIds;
    }

    function checkAll(event) {
        event.preventDefault();
        const filtered = _getFilteredPawnIds();
        let allWithPhones = families
            .map(fam => {
                if (!fam.phone) {
                    return null;
                }
                if (!filtered.includes(fam.pawnId + '')) {
                    return selectedPawns.includes(fam.pawnId + '') ? fam.pawnId + '' : null;
                }
                if (filtered.includes(fam.pawnId + '')) {
                    return fam.pawnId + '';
                }
                return null;
            })
            .filter(f => f);
        setSelectedPawns(allWithPhones);
    }

    function uncheckAll(event) {
        event.preventDefault();
        const filtered = _getFilteredPawnIds();
        setSelectedPawns(selectedPawns.filter(pawnId => !filtered.includes(pawnId)));
    }

    function filterTable(field, value) {
        let newTableFilter = { ...tableFilter };
        newTableFilter[field] = value;
        setTableFilter(newTableFilter);
    }

    function resetFilters() {
        setTableFilter({ studyId: '', username: '', group: '' });
    }

    function renderModalContent({ closeModal }) {
        if (isLoading) {
            return (
                <div>
                    <Loader type="inline" loading />
                </div>
            );
        }

        if (!families || families.length === 0) {
            return (
                <div>
                    <div className="alert alert-info">
                        <p>No users available to send a reminder.</p>
                    </div>
                    <button className="btn btn-default" onClick={() => closeModal()} type="button">
                        Close
                    </button>
                </div>
            );
        }

        if (sendResponse) {
            let { sendFail, sendSuccess } = sendResponse;
            let totalFamilies = sendFail.length + sendSuccess.length;
            if (sendResponse.sendFail.length > 0) {
                return (
                    <div>
                        <h4>
                            <span className="text-danger">
                                <CrossIcon />
                            </span>
                            &nbsp;&nbsp;Message failed to send to {sendFail.length} of {totalFamilies} families.
                        </h4>
                        <ul className={style.failList}>
                            {sendFail.map(fam => (
                                <li key={fam.pawnId}>
                                    <p>
                                        <span className={style.label}>Username:</span> {fam.username}
                                    </p>
                                    <p>
                                        <span className={style.label}>Reason:</span> {fam.reason}
                                    </p>
                                </li>
                            ))}
                        </ul>
                        <p>
                            If this is unexpected, please contact 3C Institute for support by email at{' '}
                            <a href="mailto:support@3cisd.com" className="text-nowrap">
                                support@3cisd.com
                            </a>{' '}
                            or call{' '}
                            <a href="tel:+19843160406" className="text-nowrap">
                                (984) 316-0406
                            </a>
                            .
                        </p>
                        <div className={style.closeButton}>
                            <button className="btn btn-primary" onClick={closeModal} type="button">
                                Close
                            </button>
                        </div>
                    </div>
                );
            }
            return (
                <div>
                    <h4>
                        <span className="text-success">
                            <CheckmarkIcon />
                        </span>
                        &nbsp;&nbsp;Message sent successfully to {sendSuccess.length}{' '}
                        {sendSuccess.length > 1 ? 'families' : 'family'}.
                    </h4>
                    <div className={style.closeButton}>
                        <button className="btn btn-primary" onClick={closeModal} type="button">
                            Close
                        </button>
                    </div>
                </div>
            );
        }
        return (
            <div className={style.modalBody}>
                <div className={`form-group ${errorMessage ? 'has-error' : ''}`}>
                    <label htmlFor="message body">Message</label>
                    <textarea
                        name="message body"
                        ref={messageArea}
                        wrap="hard"
                        autoFocus
                        className={'form-control ' + style.messageBody}
                        rows="6"
                        onChange={() => setErrorMessage('')}
                    />
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                </div>
                {families && families.length > 0 && selectedPawns && (
                    <>
                        <div className={style.sendDiv}>
                            <p>
                                Send text to {selectedPawns.length} of {families.length} families?
                            </p>
                            <Button
                                title="Send"
                                className="btn btn-primary"
                                isLoading={isSending}
                                type="submit"
                                isDisabled={!!errorMessage || selectedPawns.length === 0}
                                onClick={handleSubmit}
                            />
                        </div>
                        <div className={style.tableTop}>
                            <span>
                                Showing {_getFilteredPawnIds().length} of {families.length}
                            </span>
                            {Object.values(tableFilter).some(filter => filter !== '') && (
                                <button
                                    className={`btn btn-primary btn-xs ${style.filterButton}`}
                                    type="button"
                                    onClick={resetFilters}
                                >
                                    <CrossIcon />
                                    &nbsp; Remove Filters
                                </button>
                            )}
                        </div>
                        <table className={`table table-striped table-bordered ${style.table}`}>
                            <thead>
                                <tr>
                                    <th className={style.th}>
                                        <Tooltip title="Check All" placement="right">
                                            <a
                                                className={style.toggleCheckAll}
                                                href="#"
                                                title="Check all filtered records"
                                                onClick={checkAll}
                                            >
                                                <span className="glyphicon glyphicon-check" />
                                            </a>
                                        </Tooltip>
                                        <Tooltip title="Uncheck All" placement="right">
                                            <a
                                                className={style.toggleCheckAll}
                                                href="#"
                                                title="Uncheck all filtered records"
                                                onClick={uncheckAll}
                                            >
                                                <span className="glyphicon glyphicon-unchecked" />
                                            </a>
                                        </Tooltip>
                                    </th>
                                    <th className={style.th}>
                                        ID Number
                                        <div className={style.filterContainer}>
                                            <input
                                                type="text"
                                                className={`form-control input-sm ${
                                                    tableFilter.studyId ? style.activeFilter : ''
                                                }`}
                                                value={tableFilter.studyId}
                                                placeholder="search..."
                                                onChange={event => filterTable('studyId', event.target.value)}
                                            />
                                            {tableFilter.studyId && (
                                                <span
                                                    className={style.cleanButton}
                                                    onClick={() => filterTable('studyId', '')}
                                                >
                                                    <CrossIcon />
                                                </span>
                                            )}
                                        </div>
                                    </th>
                                    <th className={style.th}>
                                        Username
                                        <div className={style.filterContainer}>
                                            <input
                                                type="text"
                                                className={`form-control input-sm ${
                                                    tableFilter.username ? style.activeFilter : ''
                                                }`}
                                                value={tableFilter.username}
                                                placeholder="search..."
                                                onChange={event => filterTable('username', event.target.value)}
                                            />
                                            {tableFilter.username && (
                                                <span
                                                    className={style.cleanButton}
                                                    onClick={() => filterTable('username', '')}
                                                >
                                                    <CrossIcon />
                                                </span>
                                            )}
                                        </div>
                                    </th>
                                    <th className={style.th}>
                                        First Name
                                        <div className={style.filterContainer}>
                                            <input
                                                type="text"
                                                className={`form-control input-sm ${
                                                    tableFilter.firstName ? style.activeFilter : ''
                                                }`}
                                                value={tableFilter.firstName}
                                                placeholder="search..."
                                                onChange={event => filterTable('firstName', event.target.value)}
                                            />
                                            {tableFilter.firstName && (
                                                <span
                                                    className={style.cleanButton}
                                                    onClick={() => filterTable('firstName', '')}
                                                >
                                                    <CrossIcon />
                                                </span>
                                            )}
                                        </div>
                                    </th>
                                    <th className={style.th}>
                                        Last Name
                                        <div className={style.filterContainer}>
                                            <input
                                                type="text"
                                                className={`form-control input-sm ${
                                                    tableFilter.lastName ? style.activeFilter : ''
                                                }`}
                                                value={tableFilter.lastName}
                                                placeholder="search..."
                                                onChange={event => filterTable('lastName', event.target.value)}
                                            />
                                            {tableFilter.lastName && (
                                                <span
                                                    className={style.cleanButton}
                                                    onClick={() => filterTable('lastName', '')}
                                                >
                                                    <CrossIcon />
                                                </span>
                                            )}
                                        </div>
                                    </th>
                                    <th className={style.th}>
                                        Group
                                        <div className={style.filterContainer}>
                                            <input
                                                type="text"
                                                className={`form-control input-sm ${
                                                    tableFilter.group ? style.activeFilter : ''
                                                }`}
                                                value={tableFilter.group}
                                                placeholder="search..."
                                                onChange={event => filterTable('group', event.target.value)}
                                            />
                                            {tableFilter.group && (
                                                <span
                                                    className={style.cleanButton}
                                                    onClick={() => filterTable('group', '')}
                                                >
                                                    <CrossIcon />
                                                </span>
                                            )}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {families.map(fam => {
                                    let isShowing = ['studyId', 'username', 'firstName', 'lastName', 'group'].every(
                                        field => {
                                            if (!tableFilter[field]) {
                                                return true;
                                            }

                                            if (!fam[field]) {
                                                return false;
                                            }

                                            if (fam[field].toLowerCase().includes(tableFilter[field].toLowerCase())) {
                                                return true;
                                            }

                                            return false;
                                        }
                                    );

                                    if (!isShowing) {
                                        return null;
                                    }

                                    let isRowDisabled = !fam.phone;
                                    let checkbox = (
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={selectedPawns.includes(fam.pawnId + '')}
                                                value={fam.pawnId}
                                                onChange={handleClickCheckbox}
                                                disabled={isRowDisabled}
                                            />
                                        </label>
                                    );
                                    return (
                                        <tr key={fam.pawnId} className={isRowDisabled ? style.disabledRow : ''}>
                                            <td>
                                                {isRowDisabled ? (
                                                    <Tooltip title="No phone number available" position="top">
                                                        {checkbox}
                                                    </Tooltip>
                                                ) : (
                                                    checkbox
                                                )}
                                            </td>
                                            <td>{fam.studyId}</td>
                                            <td>{fam.username}</td>
                                            <td>{fam.firstName}</td>
                                            <td>{fam.lastName}</td>
                                            <td>{fam.group}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        );
    }

    return (
        <div className={`container ${style.buttonContainer}`}>
            <Modal
                trigger={
                    <button className="btn btn-primary" type="button">
                        <PaperIcon />
                        &nbsp;&nbsp;Send Reminder
                    </button>
                }
                title="Send Reminder"
                beforeShow={fetchFamilies}
                beforeClose={loadData}
                afterClose={resetModalState}
                render={props => renderModalContent(props)}
                size="large"
            />
        </div>
    );
};

export default SendReminder;
