import React from 'react';
import { CccisdInput, CccisdSelect, Field } from 'cccisd-formik';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';
import { useLocation, Redirect } from 'cccisd-react-router';

const Appdefs = window.cccisd.appDefs;

const Register = () => {
    const { pathname, search } = useLocation();
    if (!search && pathname.startsWith('/licensing/voucher/redeem')) {
        return <Redirect to={pathname + `?redirectUrl=${pathname}`} />;
    }

    let stateOptions = null;
    try {
        stateOptions = Appdefs.pawn.fields
            .find(f => f.handle === 'state')
            .values.map(opt => ({ value: opt.value, label: opt.name }));
        stateOptions.unshift({ value: '', label: '-- select state --' });
    } catch (e) {
        // meh *shrug*
    }

    const registerLoginProps = {
        registerProps: {
            role: 'instructor',
            showPasswordFields: true,
            validateAdditionalFields: values => {
                let errors = {};
                if (!values.first_name) {
                    errors.first_name = 'First name is required.';
                }
                if (!values.last_name) {
                    errors.last_name = 'Last name is required.';
                }
                return errors;
            },
            renderAdditionalFields: () => {
                return (
                    <>
                        <Field component={CccisdInput} name="clinic" label="Health System / Clinic:" />
                        {stateOptions && (
                            <Field component={CccisdSelect} name="state" label="State:" options={stateOptions} />
                        )}
                    </>
                );
            },
        },
    };

    return <RegisterLoginPage {...registerLoginProps} />;
};

export default Register;
