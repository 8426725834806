import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import { Html } from 'cccisd-wysiwyg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import style from './style.css';
import { LicensingContext } from '../../context/LicensingContext';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

function MainLayout(props) {
    const { isLicenseNeeded, status } = useContext(LicensingContext);

    let component = <></>;
    if (!isLicenseNeeded || status === true) {
        component = props.children;
    } else if (typeof status === 'string') {
        component = <Html content={status} />;
    }

    return (
        <div className={style.wrapper}>
            <Header className={props.className} />
            <div className={style.body}>
                {props.showSecondaryNav && Fortress.auth() && (
                    <SecondaryNav className={props.className} navs={AppDefs.navs} routes={AppDefs.routes} />
                )}
                <div className={props.className}>{component}</div>
            </div>

            <div className={style.footer}>
                <Footer className={props.className} />
            </div>
        </div>
    );
}

MainLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    showSecondaryNav: PropTypes.bool,
};

MainLayout.defaultProps = {
    className: 'container',
    showSecondaryNav: false,
};

export default MainLayout;
