import React, { useState, useContext } from 'react';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import { Formik, Form, Field } from 'cccisd-formik';
import { Redirect } from 'cccisd-react-router';
import Register from '../Register';
import { LicensingContext } from '../../context/LicensingContext';
import checkVoucher from '../RedeemVoucher/checkVoucher.graphql';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const EnterVoucher = () => {
    const { fbtProviderTraining } = useContext(LicensingContext);
    const [err, setErr] = useState(null);
    const [validCodeRedirect, setValidCodeRedirect] = useState(false);
    const [isNoActionNeeded, setIsNoActionNeeded] = useState(false);

    async function handleSubmit(vals) {
        if (!vals.code) {
            return setErr('Voucher Code is required.');
        }

        if (vals.code.length !== 6) {
            return setErr('Incorrect voucher code. Voucher code should be 6 characters in length.');
        }

        const resp = await client.query({
            query: checkVoucher,
            variables: {
                code: vals.code,
                actingId: Fortress.user.acting.id,
            },
            fetchPolicy: 'network-only',
        });

        const voucherId = _get(resp, 'data.products.voucher.voucherId', false);
        if (!voucherId) {
            return setErr('Incorrect voucher code.');
        }

        const quantity = _get(resp, 'data.products.voucher.quantity', 0);
        if (quantity < 1) {
            return setErr('This voucher has already been redeemed the maximum number of times.');
        }

        const productHandle = _get(resp, 'data.products.voucher.product.handle', '');
        if (!productHandle) {
            return setErr('Incorrect voucher code.');
        }

        // needs initial still
        if (!fbtProviderTraining?.ownedLicense?.licenseId) {
            if (productHandle === 'fbt_provider_renew') {
                return setErr(
                    'Incorrect voucher code. You need a voucher for the initial FBT Training. The provided voucher is for renewals only.'
                );
            }

            if (productHandle !== 'fbt_provider_training') {
                return setErr('Incorrect voucher code. You need a voucher for the initial FBT Training.');
            }

            return setValidCodeRedirect(vals.code);
        }

        // This means User already redeemed this voucher once,
        // and probably only clicked it again to get back to the site.
        // (Here we assume 1 User only redeems each voucher once)
        const isUsedAlready = !!_get(resp, 'data.products.voucher.sourceLogList.0.logId', false);
        if (isUsedAlready) {
            return setIsNoActionNeeded(true);
        }

        // Must be renewal vouchers from here on out!
        if (productHandle !== 'fbt_provider_renew') {
            return setIsNoActionNeeded(true);
        }

        return setValidCodeRedirect(vals.code);
    }

    if (!Fortress.auth()) {
        return <Register />;
    }

    if (isNoActionNeeded) {
        return <Redirect to="/authRedirect" />;
    }

    if (validCodeRedirect) {
        return <Redirect to={`/licensing/voucher/redeem/${validCodeRedirect}`} />;
    }

    return (
        <Formik initialValues={{ code: '' }} onSubmit={handleSubmit}>
            {formikBag => (
                <Form>
                    <div className={style.enterVoucher}>
                        <Field name="code">
                            {({ field }) => (
                                <>
                                    <label>Enter Voucher Code</label>
                                    <input
                                        className={'form-control ' + (err ? style.inputErr : '')}
                                        {...field}
                                        onChange={e => {
                                            setErr(null);
                                            field.onChange(e);
                                        }}
                                    />
                                </>
                            )}
                        </Field>
                        <button disabled={formikBag.isSubmitting} type="submit" className="btn btn-primary">
                            Submit
                        </button>
                        {err && <p className={'text-danger ' + style.err}>{err}</p>}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default EnterVoucher;
