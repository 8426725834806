import React from 'react';
import Builder, { initialValues as builderInitialValues } from '../../components/Chart/Builder';
import Player from '../../components/Chart/Player';

export default {
    handle: 'childChart',
    label: 'FBT Child Chart',
    builderInitialValues,
    builder: Builder,
    player: props => <Player type="child" {...props} />,
};
