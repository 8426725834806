import React, { useContext } from 'react';
import { Html } from 'cccisd-wysiwyg';
import { LicensingContext } from '../../context/LicensingContext';
import MainLayout from '../MainLayout';

const QuestLayout = props => {
    const { isLicenseNeeded, status } = useContext(LicensingContext);

    let component = <></>;
    if (!isLicenseNeeded || status === true) {
        component = props.children;
    } else if (typeof status === 'string') {
        component = (
            <MainLayout>
                <Html content={status} />
            </MainLayout>
        );
    }

    return <div>{component}</div>;
};

export default QuestLayout;
