import React from 'react';
import { Field, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    title: '<h1 style="font-size: 32px; color: #3d3d3d; font-weight: normal;">RYSE to the challenge!</h1>',
    subtitle:
        '<h2 style="font-size: 16px; margin-bottom: 5px;">Family-based Approach for Healthy Lifestyles (RYSE)</h2>',
    idTitle: '<p style="font-style: italic;">Weight charts for patients with ID Number: </p>',
};

const HeaderBuilder = props => {
    return (
        <>
            <Field name="title" component={CccisdWysiwyg} label="Title" />
            <Field name="subtitle" component={CccisdWysiwyg} label="Subtitle" />
            <Field name="idTitle" component={CccisdWysiwyg} label="ID Title" />
            <p style={{ marginTop: '-10px' }}>
                <small>
                    Note: The strings{' '}
                    <i>
                        {/* eslint-disable-next-line no-template-curly-in-string */}
                        <b>{'${studyId}'}</b>
                    </i>
                    ,{' '}
                    <i>
                        {/* eslint-disable-next-line no-template-curly-in-string */}
                        <b>{'${fullName}'}</b>
                    </i>
                    , and{' '}
                    <i>
                        {/* eslint-disable-next-line no-template-curly-in-string */}
                        <b>{'${username}'}</b>
                    </i>{' '}
                    will be replaced in ID Title with their actual values.
                </small>
            </p>
        </>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(HeaderBuilder);
