import React from 'react';
import axios from 'cccisd-axios';
import IconArrowLeft from 'cccisd-icons/arrow-left6';
import { NavbarLink } from 'cccisd-header';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

export default function ReturnToUserLink() {
    async function returnToUser(e) {
        e && e.preventDefault();

        await axios.get(Boilerplate.route('api.nexus.user.logoutAs'));
        window.location.href = Boilerplate.url(Fortress.settings.config.after_login_url);
    }

    if (Fortress.user.loggedInAsAnotherUser) {
        return (
            <NavbarLink to="#" activeClassName="" onClick={returnToUser}>
                <IconArrowLeft spaceRight />
                <span>{Fortress.user.actualUsername}</span>
            </NavbarLink>
        );
    }

    return null;
}
