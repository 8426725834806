import React from 'react';
import PropTypes from 'prop-types';
import IconUser from 'cccisd-icons/user3';
import { NavbarLink, RoleSwitcher } from 'cccisd-header';
import style from './style.css';

const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd && window.cccisd.boilerplate;

UserDropdown.propTypes = {
    allowSwitchRoles: PropTypes.bool,
    children: PropTypes.node,
};

/*
 * Cusotm UserDropdown with the full username removed
 * so that it does not take up as much space
 */
export default function UserDropdown({ allowSwitchRoles = true, children }) {
    const roles = Fortress.user.pawns.filter(r => !['guest', 'respondent'].includes(r.handle));

    return (
        <li className="dropdown">
            <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <IconUser spaceRight />
                <i className="caret" />
            </a>
            <ul className="dropdown-menu">
                <p className={style.userDropdownUsername}>Logged in as {Fortress.user.acting.user.username}</p>
                {allowSwitchRoles && !Fortress.user.loggedInAsAnotherUser && roles.length > 1 && (
                    <>
                        <RoleSwitcher roles={roles} />
                        <li role="separator" className="divider" />
                    </>
                )}
                {!Fortress.user.loggedInAsAnotherUser && Fortress.user.user.auth_type !== 'ldap' && (
                    <>
                        <NavbarLink to="/account/profile">Update Profile</NavbarLink>
                        <li role="separator" className="divider" />
                        <NavbarLink to="/account/updatePassword">Change Password</NavbarLink>
                        <li role="separator" className="divider" />
                    </>
                )}
                {children}
                <li>
                    <a href={Boilerplate.route('api.nexus.logout')}>Log out</a>
                </li>
            </ul>
        </li>
    );
}
