import React from 'react';
import Table from 'cccisd-graphql-table';
import Tabs from 'cccisd-tabs';
import DateRender from './DateRender';
import Status from './Status';
import style from './style.css';
import coachQuery from './coachQuery.graphql';
import pageVisitQuery from './pageVisitQuery.graphql';
import resourceQuery from './resourceQuery.graphql';
import pinnedResourceQuery from './pinnedResourceQuery.graphql';

const Appdefs = window.cccisd.appDefs;

const Progress = props => {
    let instructorLabel = 'Provider';
    try {
        instructorLabel = Appdefs.pawn.roles.find(r => r.handle === 'instructor').label || 'Provider';
    } catch (e) {} // eslint-disable-line no-empty

    const colProps = {
        sort: true,
        filter: true,
    };
    const dateProps = {
        filterSettings: {
            type: 'date',
        },
        render: DateRender,
    };

    const renderInstructorProgress = () => (
        <div className={style.table} key="coach">
            <Table
                name={instructorLabel}
                query={coachQuery}
                rowKey="pawn.pawnId"
                columns={[
                    { ...colProps, name: 'user.username', label: 'Username' },
                    { ...colProps, name: 'user.firstName', label: 'First Name' },
                    { ...colProps, name: 'user.lastName', label: 'Last Name' },
                    {
                        ...colProps,
                        name: 'assignmentProgress.status',
                        label: 'Status',
                        render: Status,
                    },
                    {
                        ...colProps,
                        ...dateProps,
                        name: 'assignmentProgress.startedAtDate',
                        label: 'Started',
                    },
                    {
                        ...colProps,
                        ...dateProps,
                        name: 'assignmentProgress.completedAtDate',
                        label: 'Completed',
                    },
                    {
                        ...colProps,
                        ...dateProps,
                        name: 'user.lastLoginDate',
                        label: 'Last Login',
                    },
                ]}
            />
        </div>
    );

    const renderPageVisits = () => (
        <div className={style.table} key="pageVisit">
            <Table
                name="pageVisit"
                query={pageVisitQuery}
                rowKey="eventId"
                isAscentOrder={false}
                columns={[
                    { ...colProps, ...dateProps, name: 'eventAt', label: 'Visit Date' },
                    { ...colProps, name: 'durationSeconds', label: 'Time Spent (sec)' },
                    { ...colProps, name: 'url', label: 'Page' },
                    { ...colProps, name: 'pawn.pawn.roleName', label: 'Role' },
                    { ...colProps, name: 'pawn.user.username', label: 'Username' },
                ]}
            />
        </div>
    );

    const renderResourceViews = () => (
        <div className={style.table} key="resourceViews">
            <Table
                name="resourceViews"
                query={resourceQuery}
                rowKey="eventId"
                isAscentOrder={false}
                columns={[
                    { ...colProps, ...dateProps, name: 'eventAt', label: 'View Date' },
                    { ...colProps, name: 'resource.label', label: 'Resource' },
                    { ...colProps, name: 'pawn.pawn.roleName', label: 'Role' },
                    { ...colProps, name: 'pawn.user.username', label: 'Username' },
                ]}
            />
        </div>
    );

    const renderPinnedResourceViews = () => (
        <div className={style.table} key="pinnedResourceViews">
            <Table
                name="pinnedResourceViews"
                query={pinnedResourceQuery}
                rowKey="eventId"
                isAscentOrder={false}
                columns={[
                    { ...colProps, ...dateProps, name: 'eventAt', label: 'View Date' },
                    { ...colProps, name: 'shortUrl.label', label: 'Resource' },
                    { ...colProps, name: 'shortUrl.targetUrl', label: 'Url' },
                    { ...colProps, name: 'pawn.pawn.roleName', label: 'Role' },
                    { ...colProps, name: 'pawn.user.username', label: 'Username' },
                ]}
            />
        </div>
    );

    const tabList = [
        {
            name: instructorLabel.toLocaleLowerCase(),
            title: instructorLabel + ' Progress',
            content: renderInstructorProgress(),
        },
        {
            name: 'pageVisits',
            title: 'Page Visits',
            content: renderPageVisits(),
        },
        {
            name: 'resourceViews',
            title: 'Resource Views',
            content: renderResourceViews(),
        },
        {
            name: 'pinnedResourceViews',
            title: 'Pinned Resource Views',
            content: renderPinnedResourceViews(),
        },
    ];

    return (
        <div className={style.pageWrap}>
            <Tabs tabList={tabList} saveInHash />
        </div>
    );
};

export default Progress;
