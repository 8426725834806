import React, { useState, useEffect, useContext } from 'react';
import _get from 'lodash/get';

import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import IconHome from 'cccisd-icons/home';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import { useParams, Redirect } from 'cccisd-react-router';
import { Html } from 'cccisd-wysiwyg';

import Register from '../Register';
import { LicensingContext } from '../../context/LicensingContext';
import checkVoucherQuery from './checkVoucher.graphql';
import style from './style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

export default function RedeemVoucher() {
    const { code: voucherCode } = useParams();
    const { fbtProviderTraining, fbtProviderRenewal } = useContext(LicensingContext);

    const [actionStatus, setActionStatus] = useState(null);
    const [errMessage, setErrMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isNoActionNeeded, setIsNoActionNeeded] = useState(false);
    const [durationMonths, setDurationMonths] = useState(12);

    useEffect(() => {
        if (Fortress.user.acting.role.handle !== 'instructor') {
            setIsNoActionNeeded(true);
        }

        handleCode(voucherCode);
    }, []);

    // returns null if valid, or string with error message
    async function handleCode() {
        if (!voucherCode) {
            return setErrMessage('Incorrect voucher link or code. Voucher code is required.');
        }

        if (voucherCode.length !== 6) {
            return setErrMessage('Incorrect voucher link or code. Voucher code should be 6 characters in length.');
        }

        const response = await client.query({
            query: checkVoucherQuery,
            variables: { code: voucherCode, actingId: Fortress.user.acting.id },
            fetchPolicy: 'network-only',
        });

        const voucherId = _get(response, 'data.products.voucher.voucherId', false);
        if (!voucherId) {
            return setErrMessage('Incorrect voucher link or code.');
        }

        const quantity = _get(response, 'data.products.voucher.quantity', 0);
        if (quantity < 1) {
            return setErrMessage('This voucher has already been redeemed the maximum number of times.');
        }

        const productHandle = _get(response, 'data.products.voucher.product.handle', '');
        if (!productHandle) {
            return setErrMessage('Incorrect voucher link or code.');
        }

        setDurationMonths(
            _get(response, 'data.products.voucher.durationMonths') || _get(fbtProviderRenewal, 'durationMonths') || 12
        );

        // needs initial still
        if (!fbtProviderTraining?.ownedLicense?.licenseId) {
            if (productHandle === 'fbt_provider_renew') {
                return setErrMessage(
                    'Incorrect voucher link or code. You need a voucher for the initial FBT Training. The provided voucher is for renewals only.'
                );
            }

            if (productHandle !== 'fbt_provider_training') {
                return setErrMessage(
                    'Incorrect voucher link or code. You need a voucher for the initial FBT Training.'
                );
            }

            setErrMessage('');
            setActionStatus('startInitial');
            return;
        }

        // This means User already redeemed this voucher once,
        // and probably only clicked it again to get back to the site.
        // (Here we assume 1 User only redeems each voucher once)
        const isUsedAlready = !!_get(response, 'data.products.voucher.sourceLogList.0.logId', false);
        if (isUsedAlready) {
            return setIsNoActionNeeded(true);
        }

        // Must be renewal vouchers from here on out!
        if (productHandle !== 'fbt_provider_renew') {
            return setIsNoActionNeeded(true);
        }

        if (!fbtProviderRenewal?.ownedLicense?.licenseId) {
            if (fbtProviderTraining?.ownedLicense?.isExpired) {
                setErrMessage('');
                setActionStatus('renew');
                return;
            }

            setErrMessage('');
            setActionStatus('extend');
            return;
        }

        // Below assumes they have at some point had an initial license AND a renewal license

        if (fbtProviderTraining.ownedLicense.isExpired) {
            setErrMessage('');
            setActionStatus('renew');
            return;
        }

        setErrMessage('');
        setActionStatus('extend');
    }

    async function redeem() {
        setIsLoading(true);
        const res = await axios.get(Boilerplate.route('licensing.api.voucher.redeem', { code: voucherCode }));

        if (res.data.status === 'error') {
            notification({
                type: 'danger',
                message: res.data.errors.code[0],
            });
            setIsLoading(false);
        } else {
            window.location = Boilerplate.url('/authRedirect');
        }
    }

    if (!Fortress.auth()) {
        return <Register />;
    }

    if (isNoActionNeeded) {
        return <Redirect to="/authRedirect" />;
    }

    if (!actionStatus && !errMessage) {
        return <Loader loading type="inline" />;
    }

    if (errMessage) {
        return (
            <div className="alert alert-warning">
                <p>{errMessage}</p>
                <br />
                <button className="btn btn-default" onClick={() => setIsNoActionNeeded(true)} type="button">
                    <IconHome spaceRight />
                    Go Home
                </button>
            </div>
        );
    }

    let actionText = '';
    let actionInstructions = '';
    switch (actionStatus) {
        case 'startInitial':
            actionText = 'start';
            actionInstructions = _get(
                fbtProviderTraining,
                'settings.customNotifications.voucherStart',
                'Would you like to start your subscription to FBT Training now?'
            );
            break;
        case 'extend':
            actionText = 'extend';
            actionInstructions = _get(
                fbtProviderRenewal,
                'settings.customNotifications.voucherExtend',
                'Would you like to extend your subscription to FBT Training for another year?'
            );
            break;
        case 'renew':
            actionText = 'renew';
            actionInstructions = _get(
                fbtProviderRenewal,
                'settings.customNotifications.voucherRenew',
                'Would you like to renew your subscription to FBT Training for another year?'
            );
            break;
        default:
            break;
    }

    return (
        <div className={style.actionView}>
            <div className="alert alert-info">
                <Html content={actionInstructions} />
                <div className={style.buttonRow}>
                    <p>
                        Do you want to {actionText} access for {durationMonths} months?
                    </p>
                    <div className={style.buttons}>
                        <button
                            className="btn btn-link"
                            type="button"
                            onClick={() => {
                                setIsNoActionNeeded(true);
                            }}
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button className="btn btn-primary" type="button" disabled={isLoading} onClick={redeem}>
                            {actionText} Access
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
